/* eslint-disable */
export const numberWithCommas = (number) => {
  if (number != null)
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  else
    return '';
};

export const validationEmail = (email) => {
  return email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/);
};

export const validationPhone = (phone) => {
  var regExp = /^\d{3}\d{4}\d{4}$/;

  if (regExp.test(phone))
    return true;
  else
    return false;
};

export const validationPassword = (pw) => {
  if (pw === null)
    return false;

  var num = pw.search(/[0-9]/g);
  var eng = pw.search(/[a-z]/ig);
  var spe = pw.search(/[`~!@@#$%^&*|₩₩₩'₩";:₩/?]/gi);

  if (pw.length < 8 || pw.length > 12)
    return false;
  else if (pw.search(/\s/) !== -1)
    return false;
  else if (num < 0 || eng < 0 || spe < 0)
    return false;
  else
    return true;
};

export const formatPhone = (str) => {
  if (str === '')
    return '';

  return str.trim().replace(/(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/, "$1-$2-$3");
};

export const formatDate = (str_date) => {
  let now_date = new Date(str_date);
  let year = now_date.getFullYear();
  let month = now_date.getMonth() + 1;
  let day = now_date.getDate();

  let returnStr = year + '-' + ('' + month).padStart(2, '0') + '-' + ('' + day).padStart(2, '0');

  return returnStr;
};

export const formatDate1 = (str_date) => {
  let now_date = new Date(str_date);
  now_date.setHours(now_date.getHours() + 9);
  let year = now_date.getFullYear();
  let month = now_date.getMonth() + 1;
  let day = now_date.getDate();

  let returnStr = year + '.' + ('' + month).padStart(2, '0') + '.' + ('' + day).padStart(2, '0');

  return returnStr;
};

export const formatDate2 = (str_date) => {
  let now_date = new Date(str_date);
  now_date.setHours(now_date.getHours() + 9);
  let year = now_date.getFullYear();
  let month = now_date.getMonth() + 1;
  let day = now_date.getDate();

  let returnStr = ('' + year).substr(2, 2) + '.' + ('' + month).padStart(2, '0') + '.' + ('' + day).padStart(2, '0');

  return returnStr;
};

export const formatDate3 = (str_date) => {
  let now_date = new Date(str_date);
  now_date.setHours(now_date.getHours() + 9);
  let year = now_date.getFullYear();
  let month = now_date.getMonth() + 1;
  let day = now_date.getDate();

  let returnStr = year + '년 ' + month + '월 ' + day + '일';

  return returnStr;
};
export const formatDate3Next = (str_date) => {
  let now_date = new Date(str_date);
  now_date.setDate(now_date.getDate() + 1);
  now_date.setHours(now_date.getHours() + 9);
  let year = now_date.getFullYear();
  let month = now_date.getMonth() + 1;
  let day = now_date.getDate();
  let returnStr = year + '년 ' + month + '월 ' + day + '일';

  return returnStr;
};

export const formatDateWeekday = (str_date) => {
  const week = new Array('일', '월', '화', '수', '목', '금', '토');

  let now_date = new Date(str_date);
  let year = now_date.getFullYear();
  let month = now_date.getMonth() + 1;
  let day = now_date.getDate();
  let week_index = now_date.getDay();

  let returnStr = ('' + year).substr(2, 2) + '.' + ('' + month).padStart(2, '0') + '.' + ('' + day).padStart(2, '0') + '(' + week[week_index] + ')';

  return returnStr;
};

export const formatDateWeekdayTime = (str_time) => {
  const week = new Array('일', '월', '화', '수', '목', '금', '토');

  let now_date = new Date(str_time);
  let year = now_date.getFullYear();
  let month = now_date.getMonth() + 1;
  let day = now_date.getDate();
  let hour = now_date.getHours();
  let minute = now_date.getMinutes();

  let week_index = now_date.getDay();
  let gubun = '오전';
  if (hour === 0)
    hour = 12;
  else {
    if (hour > 11) {
      gubun = '오후';
      if (hour > 12)
        hour = hour - 12;
    }
  }

  let returnStr = year + '년 ' + month + '월 ' + day + '일(' + week[week_index] + ')' + ' ' + gubun + ' ' + hour + '시 ' + (minute > 0 ? minute + '분' : '');

  return returnStr;
};

export const formatDateTime = (str_date) => {
  let now_date = new Date(str_date);
  let year = now_date.getFullYear();
  let month = now_date.getMonth() + 1;
  let day = now_date.getDate();
  let hour = now_date.getHours();
  let minute = now_date.getMinutes();

  let returnStr = year + '.' + ('' + month).padStart(2, '0') + '.' + ('' + day).padStart(2, '0');
  returnStr += ' ' + ('' + hour).padStart(2, '0') + ':' + ('' + minute).padStart(2, '0');

  return returnStr;
};