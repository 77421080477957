import img1 from '../assets/images/img1.png';
import img2 from '../assets/images/img2.png';
import img3 from '../assets/images/img3.png';
import img4 from '../assets/images/img4.png';
import img5 from '../assets/images/img5.png';
import img6 from '../assets/images/img6.png';
import img7 from '../assets/images/img7.png';
import img8 from '../assets/images/img8.png';
import img9 from '../assets/images/img9.png';
import img10 from '../assets/images/img10.png';
import img11 from '../assets/images/img11.png';
import img12 from '../assets/images/img12.png';
import img13 from '../assets/images/img13.png';
import img14 from '../assets/images/img14.png';
import img15 from '../assets/images/img15.png';
import img16 from '../assets/images/img16.png';
import img17 from '../assets/images/img17.png';
import img18 from '../assets/images/img18.png';
import img19 from '../assets/images/img19.png';
import img20 from '../assets/images/img20.png';
import img21 from '../assets/images/img21.png';
import img22 from '../assets/images/img22.png';
import img23 from '../assets/images/img23.png';
import img24 from '../assets/images/img24.png';
import img25 from '../assets/images/img25.png';
import img26 from '../assets/images/img26.png';
import img27 from '../assets/images/img27.png';
import img28 from '../assets/images/img28.png';
import img29 from '../assets/images/img29.png';
import img30 from '../assets/images/img30.png';
import img31 from '../assets/images/img31.png';
import img32 from '../assets/images/img32.png';
import img33 from '../assets/images/img33.png';
import img34 from '../assets/images/img34.png';
import img35 from '../assets/images/img35.png';
import img36 from '../assets/images/img36.png';
import img37 from '../assets/images/img37.png';
import img38 from '../assets/images/img38.png';
import img39 from '../assets/images/img39.png';
import img40 from '../assets/images/img40.png';
import img41 from '../assets/images/img41.png';
import img42 from '../assets/images/img42.png';
import img43 from '../assets/images/img43.png';
import img44 from '../assets/images/img44.png';
import img45 from '../assets/images/img45.png';
import img46 from '../assets/images/img46.png';
import img47 from '../assets/images/img47.png';
import img48 from '../assets/images/img48.png';
import img49 from '../assets/images/img49.png';
import img50 from '../assets/images/img50.png';
import img51 from '../assets/images/img51.png';
import img52 from '../assets/images/img52.png';
import img53 from '../assets/images/img53.png';
import img54 from '../assets/images/img54.png';
import img55 from '../assets/images/img55.png';
import img56 from '../assets/images/img56.png';
import img57 from '../assets/images/img57.png';
import img58 from '../assets/images/img58.png';
import img59 from '../assets/images/img59.png';
import img60 from '../assets/images/img60.png';
import img61 from '../assets/images/img61.png';
import img62 from '../assets/images/img62.png';
import img63 from '../assets/images/img63.png';
import img64 from '../assets/images/img64.png';
import img65 from '../assets/images/img65.png';
import img66 from '../assets/images/img66.png';
import img67 from '../assets/images/img67.png';
import img68 from '../assets/images/img68.png';
import img69 from '../assets/images/img69.png';
import img70 from '../assets/images/img70.png';
import img71 from '../assets/images/img71.png';
import img72 from '../assets/images/img72.png';
import img73 from '../assets/images/img73.png';
import img74 from '../assets/images/img74.png';
import img75 from '../assets/images/img75.png';
import img76 from '../assets/images/img76.png';
import img77 from '../assets/images/img77.png';
import img78 from '../assets/images/img78.png';
import img79 from '../assets/images/img79.png';
import img80 from '../assets/images/img80.png';
import img81 from '../assets/images/img81.png';
import img82 from '../assets/images/img82.png';
import img83 from '../assets/images/img83.png';
import img84 from '../assets/images/img84.png';
import img85 from '../assets/images/img85.png';
import img86 from '../assets/images/img86.png';
import img87 from '../assets/images/img87.png';
import img88 from '../assets/images/img88.png';
import img89 from '../assets/images/img89.png';
import img90 from '../assets/images/img90.png';
import img91 from '../assets/images/img91.png';
import img92 from '../assets/images/img92.png';
import img93 from '../assets/images/img93.png';
import img94 from '../assets/images/img94.png';
import img95 from '../assets/images/img95.png';
import img96 from '../assets/images/img96.png';
import img97 from '../assets/images/img97.png';
import img98 from '../assets/images/img98.png';
import img99 from '../assets/images/img99.png';
import img100 from '../assets/images/img100.png';
import img101 from '../assets/images/img101.png';
import img102 from '../assets/images/img102.png';
import img103 from '../assets/images/img103.png';
import img104 from '../assets/images/img104.png';
import img105 from '../assets/images/img105.png';
import img106 from '../assets/images/img106.png';
import img107 from '../assets/images/img107.png';
import img108 from '../assets/images/img108.png';
import img109 from '../assets/images/img109.png';
import img110 from '../assets/images/img110.png';
import img111 from '../assets/images/img111.png';
import img112 from '../assets/images/img112.png';
import img113 from '../assets/images/img113.png';
import img114 from '../assets/images/img114.png';
import img115 from '../assets/images/img115.png';
import img116 from '../assets/images/img116.png';
import img117 from '../assets/images/img117.png';
import img118 from '../assets/images/img118.png';
import img119 from '../assets/images/img119.png';
import img120 from '../assets/images/img120.png';
import img121 from '../assets/images/img121.png';
import img122 from '../assets/images/img122.png';
import img123 from '../assets/images/img123.png';
import logo1 from '../assets/images/logo1.png';
import logo2 from '../assets/images/logo2.png';
import logo3 from '../assets/images/logo3.png';

const data = {
  img1,
  img2,
  img3,
  img4,
  img5,
  img6,
  img7,
  img8,
  img9,
  img10,
  img11,
  img12,
  img13,
  img14,
  img15,
  img16,
  img17,
  img18,
  img19,
  img20,
  img21,
  img22,
  img23,
  img24,
  img25,
  img26,
  img27,
  img28,
  img29,
  img30,
  img31,
  img32,
  img33,
  img34,
  img35,
  img36,
  img37,
  img38,
  img39,
  img40,
  img41,
  img42,
  img43,
  img44,
  img45,
  img46,
  img47,
  img48,
  img49,
  img50,
  img51,
  img52,
  img53,
  img54,
  img55,
  img56,
  img57,
  img58,
  img59,
  img60,
  img61,
  img62,
  img63,
  img64,
  img65,
  img66,
  img67,
  img68,
  img69,
  img70,
  img71,
  img72,
  img73,
  img74,
  img75,
  img76,
  img77,
  img78,
  img79,
  img80,
  img81,
  img82,
  img83,
  img84,
  img85,
  img86,
  img87,
  img88,
  img89,
  img90,
  img91,
  img92,
  img93,
  img94,
  img95,
  img96,
  img97,
  img98,
  img99,
  img100,
  img101,
  img102,
  img103,
  img104,
  img105,
  img106,
  img107,
  img108,
  img109,
  img110,
  img111,
  img112,
  img113,
  img114,
  img115,
  img116,
  img117,
  img118,
  img119,
  img120,
  img121,
  img122,
  img123,
  logo1,
  logo2,
  logo3,
};

export default data;