import axios from 'axios';
import Config from '../Config';

export const requestPost = async (url, body, callback) => {
  try {
    const response = await axios.post(`${Config.API_URL}${url}`, body);    
    callback(response.data);
  } catch (error) {    
    throw error;
  }
};

export const requestUpload = async (url, body, callback) => {
  try {
    const response = await axios.post(`${Config.API_URL}${url}`, body, {headers: {      
      'Content-Type': 'multipart/form-data',
    }});

    callback(response.data);
  } catch (error) {
    throw error;
  }
};