import {progressActionType} from './ProgressActions'

const initProgress = {
  progressStatus: false,
};

const progress = (state = initProgress, action) => {
  switch (action.type) {
    case progressActionType.inProgoress:
      return {
        ...state,
        progressStatus: true,
      };
    case progressActionType.completeProgress:
      return {
        ...state,
        progressStatus: false,
      };
    default:
      return state;
  }
};

export default progress;
