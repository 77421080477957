import React, { useEffect, useState,useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import images from '../libs/images';
import { delAllCookie, getCookie, itemKey, setCookie } from '../libs/cookie';
import Config from '../Config';
import { requestPost } from '../libs/request';
import api from '../libs/api';

const Header = ({ title, mTitle = '', mReview = 0, mType = 0 }) => {
  const navigate = useNavigate();

  const [login, setLogin] = useState(0);
  const [membership, setMembership] = useState(0);
  const [alimCnt, setAlimCnt] = useState(0);
  const [isSubMenuVisible, setIsSubMenuVisible] = useState(false);
  const [subMenuPosition, setSubMenuPosition] = useState({ left: 0, top: 73 });
  const customerCenterRef = useRef(null);  // customerCenter 요소에 대한 참조 생성

  const toggleSubMenu = () => {
    setIsSubMenuVisible(!isSubMenuVisible);
  };

  const updateSubMenuPosition = () => {
    if (customerCenterRef.current) {
      const rect = customerCenterRef.current.getBoundingClientRect(); 
      setSubMenuPosition({ left: rect.left-2, top: 73}); // 요소의 왼쪽과 아래에 맞게 설정
    }
  };

  useEffect(() => {
    updateSubMenuPosition();
      setTimeout(()=>{updateSubMenuPosition();},300)
    
    
    const c_login = getCookie(itemKey.login);
    setLogin(c_login ? parseInt(c_login) : 0);

    if (getCookie(itemKey.user_id)) {
      requestPost(api.getUserInfoById, { id: getCookie(itemKey.user_id) }, (result) => {
        if (result) {
          setCookie(itemKey.nick_name, result.nick_name);
          setCookie(itemKey.profile, result.profile);
          setMembership(result.membership);
          setAlimCnt(result.alim_cnt);
        }
        else {
          setCookie(itemKey.nick_name, '');
          setCookie(itemKey.profile, '');
          setMembership(0);
          setAlimCnt(0);
        }
      });
    }
    else {
      setCookie(itemKey.nick_name, '');
      setCookie(itemKey.profile, '');
      setMembership(0);
      setAlimCnt(0);
    }
    updateSubMenuPosition(); // 초기 렌더링 시 위치 설정
    window.addEventListener('resize', updateSubMenuPosition); // 윈도우 리사이즈 이벤트 감지

    return () => {
      window.removeEventListener('resize', updateSubMenuPosition); // 컴포넌트 언마운트 시 이벤트 리스너 제거
    };
  }, []);

  return (
    <>
      <div className='pc'>
        <div className='header'>
          <div className='flex1'>
            <a href='/'>
              <img src={images.logo3} className='w250' alt='' />
            </a>
          </div>
          <div className='gnb'>
            <a href='/program' className={title === '프로그램' ? 'active' : ''}>
              <span>프로그램</span>
            </a>
            <a href='/webzine' className={'ml60' + (title === '웹진' ? ' active' : '')}>
              <span>웹진</span>
            </a>
            <a href='/review' className={'ml60' + (title === '커뮤니티' ? ' active' : '')}>
              <span>커뮤니티</span>
            </a>
            <a href='/about' className={'ml60' + (title === 'About' ? ' active' : '')}>
              <span>About</span>
            </a>
          </div>
          <div className='flex-end flex1'>
          <button className='flex-row-center customerCenter' onClick={toggleSubMenu} ref={customerCenterRef}>
                <img src={images.img117} className='w24'  alt=''/>
                <span className="font16 color9 fr ml6">고객센터</span>
              </button>
              <div className="v-line4"></div>
            {login === 0 && (
              <a href='/login' className='flex-row-center'>
                <img src={images.img90} className='w24' alt='' />
                <span className='font16 color9 fr ml6'>로그인</span>
              </a>
            )}
            {login === 1 && (
              <button className='h24 flex-row-center' onClick={() => {
                delAllCookie();
                window.location = '/login';
              }}>
                <img src={images.img91} className='w24' alt='' />
                <p className='font16 color9 fr ml6'>로그아웃</p>
              </button>
            )}
            {membership === 0 && (
              <a href='/membership' className='ml40 btn15'>
                <img src={images.img96} className='w24' alt='' />
                <span className='ml6'>멤버십 가입</span>
              </a>
            )}
            {login === 1 && (
              <>
                <button className='ml40 h32' onClick={() => window.location = '/myPage?tab=5'}>
                  <div className='flex-center w32 h32 relative'>
                    <img src={images.img58} className='w22' alt='' />
                    {alimCnt > 0 && <span className='alim'>{alimCnt > 99 ? '99+' : alimCnt}</span>}
                  </div>
                </button>
                <button className='ml30 flex-row-center' onClick={() => window.location = '/myPage'}>
                  <div className='avatar w40 h40' style={{ background: 'url("' + (getCookie(itemKey.profile) !== '' ? Config.IMG_URL + getCookie(itemKey.profile) : images.img57) + '")' }}></div>
                  <p className='ml10 font16 fb text-left'>{getCookie(itemKey.nick_name)}</p>
                </button>
              </>
            )}
          </div>
        </div>
      </div>
      <div className='mobile'>
        {mType === 0 && (
          <>
            <div className='m-header'>
              <div className='flex1'></div>
              <a href='/'>
                <img src={images.logo3} className='w164' alt='' />
              </a>
              <div className='flex1 flex-end'>
                {mReview === 1 && <button className='font14 color11 fb' onClick={() => window.location = '/reviewAdd'}>리뷰 작성</button>}
              </div>
            </div>
          </>
        )}
        {mType === 1 && (
          <div className='m-header'>
            <button className='h-100 flex-row-center' onClick={() => navigate(-1)}>
              <img src={images.img78} className='w24' alt='' />
            </button>
            <p className='font16 color7 fb'>{mTitle}</p>
            <div className='w24'></div>
          </div>
        )}
      </div>
      {isSubMenuVisible && (
            <div className="sub-menu" style={{ top: `${subMenuPosition.top}px`, left: `${subMenuPosition.left}px` }}>
              <a href="/notice" className="font16 color9 fr sub-menu-a">공지사항</a>
              <a href="/faq" className="mt8 font16 color9 fr sub-menu-a">FAQ</a>
              <a href="/inquiryAdd" className="mt8 font16 color9 fr sub-menu-a">1:1문의</a>
            </div>
          )}
          
    </>
  )
}

export default Header
