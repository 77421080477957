import Cookies from 'universal-cookie';
const cookies = new Cookies();

export const itemKey = {
  user_id: 'essene_user_id',
  profile: 'essene_profile',
  nick_name: 'essene_nick_name',
  login: 'essene_login',
};

export const setCookie = (key, value) => {  
  let maxAge = 86400;
  
  try {
    cookies.set(key, typeof value !== 'string' ? String(value) : value, {maxAge});
  } catch (error) {
    throw error;
  }
}

export const getCookie = (key) => {
  try {
    const value = cookies.get(key)
    return value;
  } catch(error) {
    throw error;
  }
}

export const delCookie = (key) => {  
  cookies.remove(key, { path: '/' });
};

export const delAllCookie = () => {  
  cookies.remove(itemKey.user_id, { path: '/' });
  cookies.remove(itemKey.profile, { path: '/' });
  cookies.remove(itemKey.nick_name, { path: '/' });
  cookies.remove(itemKey.login, { path: '/' });
};