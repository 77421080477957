export const progressActionType = {
  inProgoress: 'inProgress',
  completeProgress: 'completeProgress',
};

export const inProgress = dispatch => {
  dispatch({
    type: progressActionType.inProgoress,
  });
};
export const completeProgress = dispatch => {
  dispatch({
    type: progressActionType.completeProgress,
  });
};
