import {createStore, combineReducers, applyMiddleware} from 'redux';
import thunk from 'redux-thunk';
import progress from './progress/ProgressReducer';

export default createStore(
  combineReducers({
    progress,
  }),
  {},
  applyMiddleware(thunk),
);
