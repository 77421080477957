import React, { useEffect } from 'react';
import { formatDateTime } from '../libs/utils';

const NoticeItem = ({ data, onClick, mobile, home, scrollPos , index }) => {

  const windowWidth = window.innerWidth

  return (
    <button className={'card6 pc-mt20' + (home ? (scrollPos > (mobile ? 735 : 1720) ? ' fade-up-ani ani-delay-' + (100 * (index + 2)) : '') : "")} onClick={onClick}>
      <div className='flex-between w-100'>
        <p className='pc-font20 color1 fb pc2-w100 text-left'>{data.type_name}</p>
        <div className='pc1-ml20 flex1 wbba text-left'>
          <p className='pc1-font14 color9'>{formatDateTime(data.reg_time)}</p>
          <p className='pc1-font24 fb color11'>{windowWidth<768 && data.title.length >= 22 ? data.title.substring(0,21) + ".." : data.title}</p>
        </div>
      </div>
    </button>
  );
};

export default NoticeItem;
