import React, { useState, useEffect } from 'react';
import images from '../libs/images';
import { delAllCookie, getCookie, itemKey } from '../libs/cookie';
import { requestPost } from '../libs/request';
import api from '../libs/api';
import categorys from '../libs/categorys';

const Menu = ({ visible, onClose }) => {
  const [login, setLogin] = useState(0);
  const [membership, setMembership] = useState(0);
  const [keyword, setKeyword] = useState('');
  const [extend1, setExtend1] = useState(false);
  const [extend2, setExtend2] = useState(false);
  const [extend3, setExtend3] = useState(false);
  const [categoryList, setCategoryList] = useState([]);

  /* eslint-disable */
  useEffect(() => {
    const c_login = getCookie(itemKey.login);
    setLogin(c_login ? parseInt(c_login) : 0);

    if (getCookie(itemKey.user_id)) {
      requestPost(api.getUserInfoById, { id: getCookie(itemKey.user_id) }, (result) => {
        if (result)
          setMembership(result.membership);
        else
          setMembership(0);
      });
    }
    else
      setMembership(0);

      requestPost(api.getCategoryList, {}, (result) => {
        if (result) {
          // "상설 프로그램"이 포함된 항목 제거
          const filteredResult = result.filter(item => item.name !== "상설 프로그램");
      
          const updatedCategoryList = [
            {
              id: 9999,
              name: "예정 프로그램",
              sub: []
            },
            {
              id: 9998,
              name: "상설 프로그램",
              sub: []
            },
            ...filteredResult  // "상설 프로그램"이 없는 result 병합
          ];
          setCategoryList(updatedCategoryList);
        } else {
          setCategoryList([
            {
              id: 9999,
              name: "예정 프로그램",
              sub: []
            },
            {
              id: 9998,
              name: "상설 프로그램",
              sub: []
            }
          ]);
        }
      });
      
  }, []);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.addEventListener('mousedown', handleClickOutside);
    }
  }, []);

  const handleClickOutside = (e) => {
    if (e.target.classList.contains('menu-popup') && !e.target.classList.contains('menu-popup-inner'))
      onClose(true);
  };

  return (
    <>
      {visible && (
        <div className='menu-popup'>
          <div className='menu-popup-inner'>
            <div className='flex-row-center'>
              <button className='h24' onClick={() => onClose(true)}>
                <img src={images.img2} className='w24' alt='' />
              </button>
              <p className='ml10 font20 colorf'>Close</p>
            </div>
            <div className='mt40 line2 w-100'></div>
            <div className='mt20 flex-between ptb18 plr14 bgf br6'>
              <img src={images.img99} className='w24' alt='' />
              <input type='text' placeholder='Search' className='flex1 bgt ph3 bn font20 h24' value={keyword} onChange={(e) => setKeyword(e.target.value)} />
              <button className='flex-center' onClick={() => window.location = window.location = '/search?keyword=' + keyword}>
                <img src={images.img26} className='w24' alt='' />
              </button>
            </div>
            <div className='mt10 line2 w-100'></div>
            <div className='mt10 scroll2'>
              <div className='flex-between'>
                <button className='font20 colorf' onClick={() => window.location = '/program'}>프로그램</button>
                {categoryList.length > 0 && (
                  <button className='h24 flex1 flex-end' onClick={() => setExtend1(!extend1)}>
                    <img src={extend1 ? images.img28 : images.img27} className='w24' alt='' />
                  </button>
                )}
              </div>
              {extend1 && (
                <div className='mt20 pd20 br10 bgf'>
                  {categoryList.map((data, index) => (
                    <div key={index}>
                      {index > 0 && <div className='mt20 line6'></div>}
                      <div className={index > 0 ? 'mt20' : ''}>
                        <a href={'/program?cat_id=' + data.id + '&cat_name=' + data.name} className='color11 font20'>{data.name}</a>
                      </div>
                    </div>
                  ))}
                </div>
              )}
              <div className='mt10 line2 w-100'></div>
              <div className='mt10 flex-between'>
                <button className='font20 colorf' onClick={() => window.location = '/webzine'}>웹진</button>
                <button className='h24 flex1 flex-end' onClick={() => setExtend2(!extend2)}>
                  <img src={extend2 ? images.img28 : images.img27} className='w24' alt='' />
                </button>
              </div>
              {extend2 && (
                <div className='mt20 pd20 br10 bgf'>
                  <div className='flex-between'>
                    <a href='/webzineIntro' className='color11 font20'>웹진소개</a>
                  </div>
                  <div className='mt20 line6'></div>
                  <div className='mt20'>
                    <a href='/webzineList?idx=0' className='color11 font20'>{categorys[0]}</a>
                  </div>
                  <div className='mt20 line6'></div>
                  <div className='mt20'>
                    <a href='/webzineList?idx=1' className='color11 font20'>{categorys[1]}</a>
                  </div>
                  <div className='mt20 line6'></div>
                  <div className='mt20'>
                    <a href='/webzineList?idx=2' className='color11 font20'>{categorys[2]}</a>
                  </div>
                  <div className='mt20 line6'></div>
                  <div className='mt20'>
                    <a href='/webzineList?idx=3' className='color11 font20'>{categorys[3]}</a>
                  </div>
                </div>
              )}
              <div className='mt10 line2 w-100'></div>
              <div className='mt10 flex-between'>
                <a href='/review' className='font20 colorf'>커뮤니티</a>
              </div>
              <div className='mt10 line2 w-100'></div>
              <div className='mt10'>
                <a href='/about' className='font20 colorf'>About</a>
              </div>
              <div className='mt10 line2 w-100'></div>
              <div className="mt10 flex-between">
                <button className="font20 colorf">고객센터</button>
                <button className="h24 flex1 flex-end" onClick={() => setExtend3(!extend3)}>
                  <img src={extend3 ? images.img28 : images.img27} className="w24" alt="" />
                  </button>
              </div>
              {extend3 && (
              <div className="mt20 pd20 br10 bgf">
                <div className="flex-between">
                  <a href="/notice" className="color11 font20">공지사항</a>
                </div>
                <div className="mt20 line6"></div>
                <div className="mt20">
                  <a href="/faq" className="color11 font20">FAQ</a>
                </div>
                <div className="mt20 line6"></div>
                <div className="mt20">
                  <a href="/inquiryAdd" className="color11 font20">1:1문의</a>
                </div>
              </div>
              )}
               <div className='mt10 line2 w-100'></div>
              {login === 0 && (
                <div className='mt20 flex-row'>
                  <a href='/login' className='h24 flex-row-center'>
                    <img src={images.img93} className='w24' alt='' />
                    <p className='font20 colorf ml10'>로그인</p>
                  </a>
                </div>
              )}
              {login === 1 && (
                <div className='mt20 flex-row'>
                  <button className='h24 flex-row-center' onClick={() => {
                    delAllCookie();
                    window.location = '/login';
                  }}>
                    <img src={images.img94} className='w24' alt='' />
                    <p className='font20 colorf ml10'>로그아웃</p>
                  </button>
                </div>
              )}
              {membership === 0 && (
                <div className='mt20 flex-row'>
                  <a href='/membership' className='h24 flex-row-center'>
                    <img src={images.img30} className='w24' alt='' />
                    <p className='font20 colorf ml10'>멤버십 가입</p>
                  </a>
                </div>
              )}
              {login === 1 && (
                <div className='mt40 flex-row'>
                  <a href='/myPage' className='h24 flex-row-center'>
                    <img src={images.img29} className='w24' alt='' />
                    <p className='font20 colorf ml10'>마이페이지</p>
                  </a>
                </div>
              )}
            </div>
            <div className='mt40 flex-center'>
              <a href='https://www.instagram.com/the_essene_official' className='h36' target='_blank' rel='noopener noreferrer'>
                <img src={images.img12} className='w36' alt='' />
              </a>
              <a href='https://www.facebook.com/the.essene' className='ml20 h36' target='_blank' rel='noopener noreferrer'>
                <img src={images.img13} className='w36' alt='' />
              </a>
              <a href='https://www.youtube.com/@masterhealers' className='ml20 h36' target='_blank' rel='noopener noreferrer'>
                <img src={images.img14} className='w36' alt='' />
              </a>
              <a href='https://halokim-healer.tistory.com/' className='ml20 h36' target='_blank' rel='noopener noreferrer'>
                <img src={images.img92} className='w36' alt='' />
              </a>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default Menu
