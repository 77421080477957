import React, { useEffect, Suspense } from 'react';
import { Provider } from 'react-redux';
import store from './redux/store';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './scss/style.scss';
import './scss/program.scss';
import routes from './routes';
import Home from './views/Home';
import Progress from './components/Progress';

const App = () => {
  useEffect(() => {
    setScreenSize();
  });

  const setScreenSize = () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  };
  
  return (
    <BrowserRouter>
      <Suspense>
        <Routes>
          {routes.map((route, idx) => {
            return (
              route.element && (
                <Route
                  key={idx}
                  path={route.path}
                  exact={route.exact}
                  name={route.name}
                  element={<route.element />}
                />
              )
            )
          })}
          <Route path="*" name="Home" element={<Home />} />
        </Routes>
      </Suspense>
      <Progress />
    </BrowserRouter>
  );
};

const ProviderApp = () => {
  return (
    <Provider store={store}>
      <App />
    </Provider>
  );
};

export default ProviderApp
