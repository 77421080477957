const data = {
    // API_URL: 'http://192.168.3.75:2303',
    // SITE_URL: 'http://192.168.3.75:3008',
    // IMG_URL: 'http://192.168.3.75:8080/essene_uploads/',
    API_URL: 'https://esseneprime.com:2303',
    SITE_URL: 'https://esseneprime.com',
    //SITE_URL: 'http://localhost:3008',
    IMG_URL: 'https://esseneprime.com/uploads/',
    kakaoJavaScript: '49b2e37a65ff4d60781c6e351f3f0b45',
    naverClientID: 'bfdfIANI8qbhzyXmSHOi',
    naverClientSecret: 'QGTOsmL3m5',
};

export default data;