import React, { useState, useEffect } from 'react';
import categorys from '../libs/categorys';
import images from '../libs/images';
import { requestPost } from '../libs/request';
import api from '../libs/api';
import { delAllCookie, getCookie, itemKey } from '../libs/cookie';

const MFooter = () => {
  const [login, setLogin] = useState(0);
  const [membership, setMembership] = useState(0);
  const [menuFlag, setMenuFlag] = useState(false);
  const [keyword, setKeyword] = useState('');
  const [extend1, setExtend1] = useState(false);
  const [extend2, setExtend2] = useState(false);
  const [extend3, setExtend3] = useState(false);
  const [categoryList, setCategoryList] = useState([]);

  useEffect(() => {
    const c_login = getCookie(itemKey.login);
    setLogin(c_login ? parseInt(c_login) : 0);

    if (getCookie(itemKey.user_id)) {
      requestPost(api.getUserInfoById, { id: getCookie(itemKey.user_id) }, (result) => {
        if (result) {
          setMembership(result.membership);
        }
        else {
          setMembership(0);
        }
      });
    }
    else {
      setMembership(0);
    }

    requestPost(api.getCategoryList, {}, (result) => {
      if (result) {
        // "상설 프로그램"이 포함된 항목 제거
        const filteredResult = result.filter(item => item.name !== "상설 프로그램");
    
        const updatedCategoryList = [
          {
            id: 9999,
            name: "예정 프로그램",
            sub: []
          },
          {
            id: 9998,
            name: "상설 프로그램",
            sub: []
          },
          ...filteredResult  // "상설 프로그램"이 없는 result 병합
        ];
        setCategoryList(updatedCategoryList);
      } else {
        setCategoryList([
          {
            id: 9999,
            name: "예정 프로그램",
            sub: []
          },
          {
            id: 9998,
            name: "상설 프로그램",
            sub: []
          }
        ]);
      }
    });
    
  }, []);


  return (
    <>
      <div className='mobile'>
        <div className='m-h56'></div>
        <div className='m-footer'>
          <button className='h32 MfooterColumn' onClick={() => window.location = '/program'}>
            <img src={images.img4} className='w32' alt='' />
            <span>프로그램</span>
          </button>
          <button className='h32 MfooterColumn' onClick={() => window.location = '/webzine'}>
            <img src={images.img112} className='w32' alt='' />
            <span>웹진</span>
          </button>
          <button className='h36 MfooterColumn menuCol' onClick={() => setMenuFlag(true)}>
            <img src={images.img3} className='w36' alt='' />
            <span>메뉴보기</span>
          </button>
          <button className='h32 MfooterColumn' onClick={() => window.location = '/schedule'}>
            <img src={images.img15} className='w32' alt='' />
            <span>스케쥴</span>
          </button>
          <button className='h32 MfooterColumn' onClick={() => window.location = '/myPage'}>
            <img src={images.img72} className='w32' alt='' />
            <span>마이페이지</span>
          </button>
        </div>
        <div className={'m-menu ' + (menuFlag ? ' menu-top-ani' : ' menu-bottom-ani')}>
          <div className='flex-center'>
            <img src={images.logo2} className='w137' alt='' />
          </div>
          <div className='mt20 flex-between pd15 bgf br6'>
            <img src={images.img99} className='w24' alt='' />
            <input type='text' placeholder='Search' className='flex1 bgt bn font16 h20' value={keyword} onChange={(e) => setKeyword(e.target.value)} />
            <button className='flex-center' onClick={() => window.location = '/search?keyword=' + keyword}>
              <img src={images.img26} className='w24' alt='' />
            </button>
          </div>
          <div className='mt20 scroll3'>
            <div className='flex-between'>
              <button className='font16 colorf' onClick={() => window.location = '/program'} style={{ whiteSpace: 'nowrap' }}>프로그램</button>
              <button className='h20' onClick={() => setExtend1(!extend1)}  style={{ width: '100%' }}></button>
              <button className='h20' onClick={() => setExtend1(!extend1)}>
                </button>
              {categoryList.length > 0 && (
                <button className='h20' onClick={() => setExtend1(!extend1)}>
                  <img src={extend1 ? images.img28 : images.img27} className='w20' alt='' />
                </button>
              )}
            </div>
            {extend1 && (
              <div className='ml20'>
                {categoryList.map((data, index) => (
                  <div key={index} className='mt20'>
                    <a href={'/program?cat_id=' + data.id + '&cat_name=' + data.name} className='font14 colorf'>{data.name}</a>
                  </div>
                ))}
              </div>
            )}
            <div className='mt20 flex-between'>
              <button className='font16 colorf' onClick={() => window.location = '/webzine'}  style={{ whiteSpace: 'nowrap' }}>웹진</button>
              <button className='h20' onClick={() => setExtend2(!extend2)}  style={{ width: '100%' }}></button>
              <button className='h20' onClick={() => setExtend2(!extend2)}>
                <img src={extend2 ? images.img28 : images.img27} className='w20' alt='' />
              </button>
            </div>
            {extend2 && (
              <div className='ml20'>
                <div className='mt20'>
                  <a href='/webzineIntro' className='font14 colorf'>웹진소개</a>
                </div>
                <div className='mt20'>
                  <a href='/webzineList?idx=0' className='font14 colorf'>{categorys[0]}</a>
                </div>
                <div className='mt20'>
                  <a href='/webzineList?idx=1' className='font14 colorf'>{categorys[1]}</a>
                </div>
                <div className='mt20'>
                  <a href='/webzineList?idx=2' className='font14 colorf'>{categorys[2]}</a>
                </div>
                <div className='mt20'>
                  <a href='/webzineList?idx=3' className='font14 colorf'>{categorys[3]}</a>
                </div>
              </div>
            )}
            <div className='mt20'>
              <a href='/review' className='font16 colorf'>커뮤니티</a>
            </div>
            <div className='mt20'>
              <a href='/about' className='font16 colorf'>About</a>
            </div>
            <div className='mt20 flex-between'>
              <button className='font16 colorf' onClick={() => window.location = '/notice'}  style={{whiteSpace:'nowrap'}}>고객센터</button>
              <button className='h20' onClick={() => setExtend3(!extend3)}  style={{ width: '100%' }}>
              </button>
              <button className='h20' onClick={() => setExtend3(!extend3)}>
                <img src={extend3 ? images.img28 : images.img27} className='w20' alt='' />
              </button>
            </div>
            {extend3 && (
              <div className='ml20'>
                <div className='mt20'>
                  <a href='/notice' className='font14 colorf'>공지사항</a>
                </div>
                <div className='mt20'>
                  <a href='/faq' className='font14 colorf'>FAQ</a>
                </div>
                <div className='mt20'>
                  <a href='/inquiryAdd' className='font14 colorf'>1:1문의</a>
                </div>
              </div>

            )}
            <div className='mt10 line2 w-100'></div>

            {login === 0 && (
              <div className='mt10 flex-row'>
                <a href='/login' className='h20 flex-row-center'>
                  <img src={images.img93} className='w20' alt='' />
                  <p className='font16 colorf ml10'>로그인</p>
                </a>
              </div>
            )}
            {login === 1 && (
              <div className='mt20 flex-row'>
                <button className='h20 flex-row-center' onClick={() => {
                  delAllCookie();
                  window.location = '/login';
                }}>
                  <img src={images.img94} className='w20' alt='' />
                  <p className='font16 colorf ml10'>로그아웃</p>
                </button>
              </div>
            )}
            {membership === 0 && (
              <div className='mt20 flex-row'>
                <a href='/membership' className='h20 flex-row-center'>
                  <img src={images.img30} className='w20' alt='' />
                  <p className='font16 colorf ml10'>멤버십 가입</p>
                </a>
              </div>
            )}
            {login === 1 && (
              <div className='mt20 flex-row'>
                <a href='/myPage' className='h20 flex-row-center'>
                  <img src={images.img29} className='w20' alt='' />
                  <p className='font16 colorf ml10'>마이페이지</p>
                </a>
              </div>
            )}
          </div>
          <div className='mt40 flex-center'>
            <a href='https://www.instagram.com/the_essene_official' className='h36' target='_blank' rel='noopener noreferrer'>
              <img src={images.img12} className='w36' alt='' />
            </a>
            <a href='https://www.facebook.com/the.essene' className='ml20 h36' target='_blank' rel='noopener noreferrer'>
              <img src={images.img13} className='w36' alt='' />
            </a>
            <a href='https://www.youtube.com/@masterhealers' className='ml20 h36' target='_blank' rel='noopener noreferrer'>
              <img src={images.img14} className='w36' alt='' />
            </a>
            <a href='https://halokim-healer.tistory.com/' className='ml20 h36' target='_blank' rel='noopener noreferrer'>
              <img src={images.img92} className='w36' alt='' />
            </a>
          </div>
          <div className={'m-footer1' + (menuFlag ? ' menu-top-ani' : ' menu-bottom-ani')}>
            <button className='h32 MfooterColumn' onClick={() => window.location = '/program'}>
              <img src={images.img4} className='w32' alt='' />
              <span>프로그램</span>
            </button>
            <button className='h32 MfooterColumn' onClick={() => window.location = '/webzine'}>
              <img src={images.img112} className='w32' alt='' />
              <span>웹진</span>
            </button>
            <button className='h36 MfooterColumn menuCol' onClick={() => setMenuFlag(false)}>
              <img src={images.img3} className='w36' alt='' />
              <span>메뉴닫기</span>
            </button>
            <button className='h32 MfooterColumn' onClick={() => window.location = '/schedule'}>
              <img src={images.img15} className='w32' alt='' />
              <span>스케쥴</span>
            </button>
            <button className='h32 MfooterColumn' onClick={() => window.location = '/myPage'}>
              <img src={images.img72} className='w32' alt='' />
              <span>마이페이지</span>
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default MFooter
