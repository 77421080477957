import { useSelector } from 'react-redux';

const Progress = () => {
  const { progressStatus } = useSelector(state => state.progress);

  if (!progressStatus)
    return null;
  
  return (
    <div className="spinner-container">
      <div className="loading-spinner">
      </div>
    </div>
  );
};

export default Progress;
