import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import PreLoadImage from '../components/PreLoadImage';
import { Autoplay } from 'swiper';
import { formatDate1} from '../libs/utils';
const MobileMainHomeProgram = ({
  list,
  setSwiperRef,
  swiperNext,
  swiperPrev,
  scrollPos,
  mobile,
  Config,
  images,
  title = '프로그램'
}) => {
  const [hoveredList, setHoveredList] = useState([]);

  const handleMouseOver = (index) => {
    const updatedList = [...hoveredList];
    updatedList[index] = true;
    setHoveredList(updatedList);
  };

  const handleMouseOut = (index) => {
    const updatedList = [...hoveredList];
    updatedList[index] = false;
    setHoveredList(updatedList);
  };

  return (
    <div className='mobile'>
      <div className="secondProTit">{title}</div>
      <div className="m-flex-row-center programSlideBox">
        <button className='h32' onClick={swiperPrev}>
          <img src={images.img110} className='w20 invertBtn' alt='' />
        </button>
        <Swiper
          onSwiper={(ref) => setSwiperRef(ref)}
          autoHeight={true}
          spaceBetween={15}
          navigation={false}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          direction='horizontal'
          effect='slide'
          breakpoints={{
            0: {
              slidesPerView: 2,  // 768px 이하일 때 슬라이드 1개
            },
            768: {
              slidesPerView: 2,  // 768px 이상일 때 슬라이드 2개
            },
          }}
          modules={[Autoplay]} 
        >
          {list.map((data, index) => {
            const now = new Date();
            const sDateTime = new Date(data.s_date_time);
            const diffInTime = sDateTime.getTime() - now.getTime();
            const diffInDays = diffInTime / (1000 * 3600 * 24);
            const isImminent = data.expire === 0 && diffInDays <= 7 && diffInDays >= 0;

            return (
              <SwiperSlide
                key={index}
                className={scrollPos > (mobile ? 30 : 120) ? `mobileProgram fade-up-ani ani-delay-${100 * (index + 2)}` : 'mobileProgram'}
              >
                <button
                  className='relative ofh'
                  onMouseOver={() => handleMouseOver(index)}
                  onMouseOut={() => handleMouseOut(index)}
                  onClick={() => window.location = '/programDetail?id=' + data.id}
                >
                  <PreLoadImage className={'bg-img1'} url={Config.IMG_URL + data.img} />
                  {(data.start === 1 && data.expire === 1) && <span className='status bg999'>신청마감</span>}
                  {data.expire === 0 && <span className='status'>신청진행중</span>}
                  {(isImminent) && <div className='programIcon'><img src={images.img122} alt='' /></div>}
                  {(data.expire === 0 && data.cnt_temp <= data.request_count) && <div className='programIcon'><img src={images.img123} alt='' /></div>}

                  <div className='b-txt'>
                    <p className='homeTitle'>{data.name}</p>
                    <p className='homeShort'>{data.short}</p>
                    <p className='homeLast'>
                      <span className='LastInner'>{data.cat_name}</span>
                      <span className='LastDivider'>|</span>
                      {data.healer_name && (
                        <>
                          <span className='LastInner'>{data.healer_name}</span>
                          <span className='LastDivider'>|</span>
                        </>
                      )}
                      <span className='LastInner'>{data.always === "Y" ? '상시신청' : '진행일시 ' + formatDate1(data.s_date)}</span>
                    </p>
                  </div>
                </button>
              </SwiperSlide>
            );
          })}
        </Swiper>
        <button className='h32' onClick={swiperNext}>
          <img src={images.img111} className='w20 invertBtn' alt='' />
        </button>
      </div>
    </div>
  );
};

export default MobileMainHomeProgram;
